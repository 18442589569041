import React from "react"

const Play = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M23.9955014,11.9977507 C23.9955014,18.6239254 18.6239254,23.9955014 11.9977507,23.9955014 C5.37157596,23.9955014 7.99110856e-16,18.6239254 0,11.9977507 C-7.99110856e-16,5.37157596 5.37157596,7.99110856e-16 11.9977507,0 C18.6208453,0.00495369129 23.9900796,5.37016312 24,11.9932521 L23.9955014,11.9977507 Z M18.5823506,11.5017891 C18.5830116,11.2491675 18.4356913,11.0164756 18.1984757,10.8954597 L8.68543923,6.0819472 C8.45716723,5.96737162 8.18129674,5.97328725 7.95888499,6.09752707 C7.73647324,6.22176688 7.60020567,6.44607271 7.6,6.68827654 L7.6,16.3111769 C7.6,16.6916034 7.9299049,17 8.33686322,17 C8.45851407,16.9994935 8.57818507,16.9711722 8.68543923,16.9175062 L18.1984757,12.1039937 C18.4425245,11.9874137 18.5971955,11.7538497 18.6,11.4976644 L18.5823506,11.5017891 Z"
      id="path-1"
    ></path>
    <use xlinkHref="#video-icon" transform="translate(-.35)" />
  </svg>
)

export default Play
