import React from 'react';
import * as Icons from './icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const Icon = ({ name, marginRight, marginLeft, className, ...other }) => {
  const IconComponent = Icons[name];

  const getClassnames = () =>
    classNames(
      'icon',
      `icon--${name}`,
      marginLeft && 'icon--margin-left',
      marginRight && 'icon--margin-right',
      className
    );

  return <IconComponent className={getClassnames()} {...other} />;
};

Icon.defaultProps = {
  marginLeft: false,
  marginRight: false,
  className: null,
};

Icon.propTypes = {
  /** The name of the icon */
  name: PropTypes.oneOf(Object.keys(Icons)).isRequired,
  /** Adds a small margin to the right of the icon */
  marginRight: PropTypes.bool,
  /** Adds a small margin to the left of the icon */
  marginLeft: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
};

export default Icon;
