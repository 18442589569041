import React from "react"

const Twitter = props => (
  <svg viewBox="0 0 38 38" {...props}>
    <defs>
      <path
        d="M19.096 0C9.249 0 1.193 8.056 1.193 17.902c0 9.846 8.056 17.902 17.903 17.902 9.846 0 17.902-8.056 17.902-17.902C36.998 8.056 28.942 0 19.096 0zm7.906 14.023v.448c0 5.221-3.878 11.189-11.188 11.189-2.238 0-4.327-.597-5.968-1.79a7.48 7.48 0 005.818-1.642c-1.79 0-3.133-1.193-3.73-2.685.597.15 1.194.15 1.79 0-1.79-.298-3.132-1.94-3.132-3.879.597.299 1.194.448 1.79.448-1.044-.746-1.79-1.94-1.79-3.282 0-.746.15-1.343.597-1.94 1.94 2.387 4.774 3.88 8.056 4.178-.15-.448-.15-.746-.15-1.045 0-2.237 1.79-3.878 3.88-3.878 1.193 0 2.088.447 2.834 1.193.895-.15 2.984-.448 2.984-.448-.597.896-1.045 2.537-1.79 3.133z"
        id="twitter-icon"
      />
    </defs>
    <use xlinkHref="#twitter-icon" transform="translate(-.75)" />
  </svg>
)

export default Twitter
