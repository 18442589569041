import React from 'react';

const ArrowRightLarge = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="#FFFFFF"
    className="icon icon--right"
  >
    <rect
      x="2.88281"
      y="16.1235"
      width="32.2475"
      height="5.37459"
      fill="#FFFFFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.002 19.002L19 0L15.1992 3.80078L30.4005 19.002L15.1992 34.2033L19 38.0041L38.002 19.002Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default ArrowRightLarge;
