import React from 'react';

const ArrowLeftGray = (props) => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32.2475"
      height="5.37459"
      transform="matrix(-1 0 0 1 36.1172 16.1235)"
      fill="#CCCCCC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.99797 19.002L20 0L23.8008 3.80078L8.59953 19.002L23.8008 34.2033L20 38.0041L0.99797 19.002Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default ArrowLeftGray;
