import React from 'react';

const Share = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
  >
    <rect x="4" y="4" width="10.2857" height="2" fill="#A8A8A8" />
    <rect y="11" width="13" height="2" fill="#A8A8A8" />
    <rect x="0.570312" width="7" height="2" fill="#A8A8A8" />
    <rect
      x="2"
      width="13"
      height="2"
      transform="rotate(90 2 0)"
      fill="#A8A8A8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1206 8.40291L13.5703 4.95317L10.1206 1.50342L11.6206 0.00341775L16.5703 4.95317L11.6206 9.90291L10.1206 8.40291Z"
      fill="#A8A8A8"
    />
  </svg>
);

export default Share;
