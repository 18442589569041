import React from "react"

const Download = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M11.6 18.9c.1.1.2.1.4.1.1 0 .3-.1.4-.1l7-7c.1-.1.2-.4.1-.5-.1-.3-.3-.4-.5-.4h-3V.5c0-.3-.2-.5-.5-.5h-7c-.3 0-.5.2-.5.5V11H5c-.2 0-.4.1-.5.3-.1.2 0 .4.1.5l7 7.1z"></path>
    <path d="M22.5 16.8c-.8 0-1.4.6-1.4 1.4v2.9H2.8v-2.9c0-.8-.6-1.4-1.4-1.4S0 17.4 0 18.2v4.3c0 .9.6 1.5 1.4 1.5h21.1c.8 0 1.4-.6 1.4-1.4v-4.3c.1-.9-.6-1.5-1.4-1.5z"></path>
    <use xlinkHref="#download-icon" transform="translate(-.35)" />
  </svg>
)

export default Download
