import React from 'react';

const Module = props => (
  <svg viewBox="0 0 72.84 80" {...props}>
    <path d="M16.88 19.91v5.87a2.19 2.19 0 004.35 0v-5.87a2.19 2.19 0 00-4.35 0z"></path>
    <path d="M62.33 14.04a1.83 1.83 0 00-1.93-1.71H12.53a1.83 1.83 0 00-1.93 1.71v17.6a1.83 1.83 0 001.93 1.71h15c0-1.5.09-2.63.13-3.42h-13.2V15.75h44v14.18H45.38c.06.78.1 1.9.13 3.42H60.4a1.83 1.83 0 001.93-1.71v-17.6z"></path>
    <path d="M31.54 26.24a5.15 5.15 0 015.16-5.13 5.21 5.21 0 015.21 5.19v20.75l15.38 5.19c3.42 1.1 4.17 5.2 3.37 8.7l-3.55 19.04H29.5l-13.89-20.8c-1.57-2.41-1.57-6.95 1.88-6.95s7.13 1.42 14 12.08v-38.1z"></path>
    <path d="M57.51 46.48h1.76a13.59 13.59 0 0013.57-13.57V13.58A13.59 13.59 0 0059.27-.02h-45.7A13.59 13.59 0 000 13.58v19.33a13.59 13.59 0 0013.57 13.57h1.76a3.025 3.025 0 000-6.05h-1.76a7.53 7.53 0 01-7.52-7.52V13.58a7.53 7.53 0 017.52-7.52h45.7a7.53 7.53 0 017.52 7.52v19.33a7.53 7.53 0 01-7.52 7.52h-1.76a3.025 3.025 0 000 6.05z"></path>
  </svg>
);

export default Module;
