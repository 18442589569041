import React from 'react';

const MailIconSmall = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2939_190383)">
      <path
        d="M0 14.6167L-4.58159e-07 4.51144L2 6.6167L2 14.6167L0 14.6167Z"
        fill="white"
      />
      <path
        d="M16 14.6167L16 4.51144L14 6.6167L14 14.6167L16 14.6167Z"
        fill="white"
      />
      <rect y="13" width="16" height="2" fill="white" />
      <path
        d="M7.99921 9.585L1.41421 3H14.5855L7.99921 9.585Z"
        stroke="white"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2939_190383">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MailIconSmall;
