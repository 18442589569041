import React from 'react';

const ExternalLink = (props) => (
  <svg viewBox="0 0 22 22" {...props}>
    <path d="M16 0C15.4477 0 15 0.447715 15 1C15 1.55228 15.4477 2 16 2H18.5858L9.29289 11.2929C8.90237 11.6834 8.90237 12.3166 9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071L20 3.41421V6C20 6.55228 20.4477 7 21 7C21.5523 7 22 6.55228 22 6V1C22 0.447715 21.5523 0 21 0H16Z" />
    <path d="M2 5C2 4.44772 2.44772 4 3 4H7.75C8.30228 4 8.75 3.55228 8.75 3C8.75 2.44772 8.30228 2 7.75 2H3C1.34315 2 0 3.34315 0 5V19C0 20.6569 1.34315 22 3 22H17C18.6569 22 20 20.6569 20 19V15.1562C20 14.604 19.5523 14.1562 19 14.1562C18.4477 14.1562 18 14.604 18 15.1562V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V5Z" />
    <use xlinkHref="#externallink-icon" transform="translate(-.35)" />
  </svg>
);

export default ExternalLink;
