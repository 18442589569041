export const logDownload = (download) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'click', {
      event_category: 'Document',
      event_label: download,
    });
  }
};

export const logVideoPlay = (video) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'play', {
      event_category: 'Video',
      event_label: video,
    });
  }
};

export const logOutboundLink = (url) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'click', {
      event_category: 'Outbound Link',
      event_label: url,
    });
  }
};
