import React from 'react';

const Email = (props) => {
  return (
    <svg viewBox="0 0 37 36" {...props}>
      <defs>
        <path
          d="M19.096 0c9.846 0 17.902 8.056 17.902 17.902 0 9.846-8.056 17.902-17.902 17.902-9.847 0-17.903-8.056-17.903-17.902C1.193 8.056 9.25 0 19.096 0zm7.031 8.35a.646.646 0 00-.374-.114.636.636 0 00-.333.094L8.104 18.32c-.236.132-.347.336-.333.614.021.284.16.475.416.572l4.11 1.686 11.125-9.626-8.991 11.02v3.632c0 .139.04.266.12.38.08.114.185.196.317.245a.563.563 0 00.229.041c.215 0 .385-.08.51-.24l2.518-3.069L22.84 25.5a.639.639 0 00.572-.031.656.656 0 00.333-.468l2.664-15.984a.65.65 0 00-.28-.666z"
          id="email-icon"
        />
      </defs>
      <use xlinkHref="#email-icon" transform="translate(-.35)" />
    </svg>
  );
};

export default Email;
