import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import './styles.scss';

const Column = ({
  size,
  xsmall,
  small,
  medium,
  large,
  xlarge,
  offset,
  xsmallOffset,
  smallOffset,
  mediumOffset,
  largeOffset,
  xlargeOffset,
  children,
  className,
  ...other
}) => {
  /* Throw error if 'size' provided, along with 'small', 'medium', or 'large'
   */
  if (size && (small || medium || large)) {
    throw new Error('Cannot combine "size" param with custom sizes.');
  }

  const getClassNames = () =>
    classNames(
      'column',
      size !== null && `column--${size}`,
      xsmall !== null && `column--xsm--${xsmall}`,
      small !== null && `column--sm--${small}`,
      medium !== null && `column--md--${medium}`,
      large !== null && `column--lg--${large}`,
      xlarge !== null && `column--xl--${xlarge}`,
      offset !== null && `column--offset--${offset}`,
      xsmallOffset !== null && `column--xsm--offset--${xsmallOffset}`,
      smallOffset !== null && `column--sm--offset--${smallOffset}`,
      mediumOffset !== null && `column--md--offset--${mediumOffset}`,
      largeOffset !== null && `column--lg--offset--${largeOffset}`,
      xlargeOffset !== null && `column--xl--offset--${xlargeOffset}`,
      className
    );

  return (
    <div className={getClassNames()} {...other}>
      {children}
    </div>
  );
};

Column.defaultProps = {
  size: null,
  xsmall: null,
  small: null,
  medium: null,
  large: null,
  xlarge: null,
  offset: null,
  xsmallOffset: null,
  smallOffset: null,
  mediumOffset: null,
  largeOffset: null,
  xlargeOffset: null,
  className: null,
};

Column.propTypes = {
  /** The size of the column from 1 to 12. Intended for one breakpoint at 'md'. */
  size: PropTypes.number,
  /** The size of the columns on extra small screens. Range 1 to 12 */
  xsmall: PropTypes.number,
  /** The size of the columns on small screens. Range 1 to 12 */
  small: PropTypes.number,
  /** The size of the columns on medium screens. Range 1 to 12 */
  medium: PropTypes.number,
  /** The size of the columns on large screens. Range 1 to 12 */
  large: PropTypes.number,
  /** The size of the columns on extra large screens. Range 1 to 12 */
  xlarge: PropTypes.number,
  /** The offset of the column from 1 to 12. Intended for one breakpoint at 'md'. */
  offset: PropTypes.number,
  /** The offset of the column on extra small screens. Range 1 to 12 */
  xsmallOffset: PropTypes.number,
  /** The offset of the column on small screens. Range 1 to 12 */
  smallOffset: PropTypes.number,
  /** The offset of the column on medium screens. Range 1 to 12 */
  mediumOffset: PropTypes.number,
  /** The offset of the column on large screens. Range 1 to 12 */
  largeOffset: PropTypes.number,
  /** The offset of the column on extra large screens. Range 1 to 12 */
  xlargeOffset: PropTypes.number,
  /** A custom class name */
  className: PropTypes.string,
};

export default Column;
