import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Metadata from '../Metadata';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import './styles.scss';

/** The `Layout` component is used to wrap pages with a header and footer */

const Layout = ({ children, title, className, ...other }) => (
  <div className={classNames('layout', className)} {...other}>
    <Metadata title={title} />
    <Helmet>
      <link
        href="https://fast.fonts.net/cssapi/c9b374f7-12a8-4b1f-840e-46d3b14d6016.css"
        rel="stylesheet"
      />
      <script
        type="text/javascript"
        src="https://fast.fonts.net/jsapi/c9b374f7-12a8-4b1f-840e-46d3b14d6016.js"
      ></script>
    </Helmet>
    <Header />
    <main role="main" id="content">
      {children}
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  /** The page title */
  title: PropTypes.string.isRequired,
  /** A custom class name */
  className: PropTypes.string,
};

export default Layout;
