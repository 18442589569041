import React from 'react';

const Twitter = (props) => (
  <svg viewBox="0 0 54 54" fill="none" {...props}>
    <circle cx="27" cy="27" r="27" fill="#FF0000" />
    <path
      d="M42.3313 19.3049C41.9633 17.9253 40.879 16.8388 39.5022 16.4701C37.0067 15.8 27 15.8 27 15.8C27 15.8 16.9934 15.8 14.4978 16.4701C13.121 16.8388 12.0367 17.9253 11.6687 19.3049C11 21.8055 11 27.0227 11 27.0227C11 27.0227 11 32.2399 11.6687 34.7405C12.0367 36.1201 13.121 37.1613 14.4978 37.53C16.9934 38.2001 27 38.2001 27 38.2001C27 38.2001 37.0066 38.2001 39.5022 37.53C40.879 37.1613 41.9633 36.1201 42.3313 34.7405C43 32.2399 43 27.0227 43 27.0227C43 27.0227 43 21.8055 42.3313 19.3049V19.3049ZM23.7272 31.7595V22.2858L32.0909 27.0228L23.7272 31.7595V31.7595Z"
      fill="white"
    />
  </svg>
);

export default Twitter;
